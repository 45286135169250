import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import OptimaEhandelBooking from '../components/optimaEhandelBooking'
import PageHeader from '../components/pageHeader'

const BokaKurs = () => (
  <Layout>
    <PageHeader text='Boka din utbildning' />
    <Seo title='Boka-utbildning' />
    <OptimaEhandelBooking/>
  </Layout>
)

export default BokaKurs
