
import React from 'react'
 import * as OptimaEhandelShoppCSS from './css/optimaEhandelBooking.module.css'

const OptimaEhandelBooking = () => (
<div className={` ${OptimaEhandelShoppCSS.parent}`}>
    <iframe src='https://danderyds_trafikskola_aboaeaeoa.web.stroptima.se/kalender' title="e-handel"></iframe>
</div>
)
export default OptimaEhandelBooking
